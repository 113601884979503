<script setup lang="ts">
import { utilBasePrices } from '@/utils/utilBasePrice'
import { useDebounceFn } from '@vueuse/core'
import { differenceInCalendarDays, isPast, subDays } from 'date-fns'
import { storeToRefs } from 'pinia'
import { CalendarDay } from 'v-calendar/dist/types/src/utils/page'
import { LoadingIds } from '~/constants/loadingIds'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { useLoadingStore } from '~/store/loading'
import { basePricesConfiguration } from '~~/src/config/BasePrices'
import { TextSizes } from '~~/src/constants/textSizes'
import { useCalendarConversion } from '~~/src/hooks/useCalendarRangeConversion.js'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useBasePricesStore } from '~~/src/store/basePrices'
import { useClosingDatesStore } from '~~/src/store/closingDates'
import { useEventsStore } from '~~/src/store/events'
import { BasePricePredictionPreviewType } from '~~/src/submodules/sharedTypes/common/BasePrice'
import { utilCalendar } from '~~/src/utils/utilCalendar'
import { utilDate } from '~~/src/utils/utilDate'

const eventsStore = useEventsStore()
const accommodationStore = useAccommodationsStore()
const basePricesStore = useBasePricesStore()
const closingDatesStore = useClosingDatesStore()
const { editableBasePrice, basePrices, minSelectableDays } = storeToRefs(basePricesStore)

const alteredWeightHelperText = computed(() =>
	eventsStore.getEditableEventHasAlteredWeight ? TranslationKeys.IMPACT_FIELD_HELPER_TEXT : undefined
)

const isEditing = computed(() => basePricesStore.editableBasePrice.originalIndex != undefined)
const currentPrice = computed(() => basePricesStore.editableBasePrice.basePrice.price?.toString() || '-')
const previousPrice = computed(() => {
	const basePriceData = basePrices.value
		.get(editableBasePrice.value.accommodationId)
		?.get(editableBasePrice.value.roomTypeId)

	if (!basePriceData) {
		return '-'
	}

	const { positionInfo } = utilBasePrices.getInsertionData(basePriceData, basePricesStore.editableBasePrice.basePrice)
	return positionInfo?.from ? basePriceData[positionInfo?.from - 1]?.price?.toString() : '-'
})
const nextPrice = computed(() => {
	const basePriceData = basePrices.value
		.get(editableBasePrice.value.accommodationId)
		?.get(editableBasePrice.value.roomTypeId)

	if (!basePriceData) {
		return '-'
	}

	const { positionInfo } = utilBasePrices.getInsertionData(basePriceData, basePricesStore.editableBasePrice.basePrice)
	return positionInfo?.from ? basePriceData[positionInfo?.from + 1]?.price?.toString() : '-'
})
const currentPeriodLength = computed(() =>
	utilDate.daysBetween(editableBasePrice.value.basePrice.dateRange.from, editableBasePrice.value.basePrice.dateRange.to)
)

const { connectedVModel, onVmodelUpdate } = useCalendarConversion(basePricesStore.editableBasePrice.basePrice.dateRange)
const singleDateVModel = ref(basePricesStore.editableBasePrice.basePrice.dateRange.to)
const isPeriodStartingOnPast = isPast(basePricesStore.editableBasePrice.basePrice.dateRange.from)
const isRangePicker = ref(true)
const onDayClick = (date: CalendarDay) => {
	if (isPeriodStartingOnPast) {
		let endDate = subDays(new Date(), 1)

		if (differenceInCalendarDays(date.endDate, new Date()) >= 0) {
			endDate = date.endDate
		}

		singleDateVModel.value = endDate
		editableBasePrice.value.basePrice.dateRange.to = endDate
		isRangePicker.value = false
		attrs.value = [
			{
				key: 'single-date',
				highlight: true,
				dates: {
					start: editableBasePrice.value.basePrice.dateRange.from,
					end: endDate,
				},
			},
		]
	}
}
const displayedDate = computed(() => basePricesStore.editableBasePrice.basePrice.dateRange)
const highlightedDates = computed(() => {
	const { accommodationId, roomTypeId } = basePricesStore.editableBasePrice
	const closingDates = closingDatesStore.getClosingDatesByAccommodationAndRoomTypeId(accommodationId, roomTypeId)

	// occupied dates in gray, full
	return utilCalendar.dateRangesToHighlightedDates([...basePricesStore.getHighlightedDates, ...closingDates], 'gray')
})

const basePriceChangeFn = () => {
	basePricesStore.requestEditablePreview()
}
const onBasePriceChange = (_textVal: string, val: number) => {
	useLoadingStore().addLoading(LoadingIds.GET_PREDICTION_PREVIEW)
	basePricesStore.editableBasePrice.basePrice.price = val
	useDebounceFn(basePriceChangeFn, 3000)()
}

watch(basePricesStore.previews, () => {
	previewChartData.value = basePricesStore.getChartPreview(
		basePricesStore.editableBasePrice.roomTypeId,
		BasePricePredictionPreviewType.Temporary
	)
})
const previewChartData = ref(
	basePricesStore.getChartPreview(basePricesStore.editableBasePrice.roomTypeId, BasePricePredictionPreviewType.Regular)
)

const basePricesChartData = computed(() => {
	return basePricesStore.getChartBasePrices(
		basePricesStore.editableBasePrice.accommodationId,
		basePricesStore.editableBasePrice.roomTypeId
	)
})
const currency = computed(
	() => accommodationStore.getAccommodationById(basePricesStore.editableBasePrice.accommodationId)!.currency
)

const displayFrom = computed(() => utilDate.addMonths(basePricesStore.editableBasePrice.basePrice.dateRange.from, -1))
const displayTo = computed(() => utilDate.addMonths(basePricesStore.editableBasePrice.basePrice.dateRange.to, 1))

const attrs = ref<any>([])

const lastSelectableDate = computed(() => {
	const currentBasePrices = basePricesStore.basePrices
		.get(editableBasePrice.value.accommodationId)
		?.get(editableBasePrice.value.roomTypeId)!

	return subDays(currentBasePrices[currentBasePrices.length - 1].dateRange.from, 1)
})
</script>

<template>
	<div class="flex min-h-[370px] gap-6">
		<CommonFrameWithTitle :title="TranslationKeys.BASE_PRICE" class="flex-1">
			<slot>
				<div class="flex flex-col gap-6">
					<CommonText
						:text="TranslationKeys.SELECTED_PERIOD_MIN_DAYS"
						:text-size="TextSizes.BODY_REGULAR"
						:replacements="[minSelectableDays.toString()]"
					/>
					<div class="base-prices-date-picker flex">
						<v-date-picker
							v-if="isRangePicker"
							v-model="connectedVModel"
							color="sky"
							:is-required="true"
							is-range
							style="border: none"
							v-on:update:modelValue="onVmodelUpdate"
							:attributes="highlightedDates"
							:min-date="basePricesConfig.firstSelectableDate"
							:max-date="lastSelectableDate"
							data-id="base-prices-calendar"
							@dayclick="onDayClick"
						/>
						<v-date-picker
							v-else
							v-model="singleDateVModel"
							color="sky"
							:is-required="true"
							style="border: none"
							:attributes="attrs"
							:min-date="basePricesConfig.firstSelectableDate"
							:max-date="lastSelectableDate"
							data-id="base-prices-calendar"
							@dayclick="onDayClick"
						/>

						<div class="h-full border-l border-dark-blue-300" />

						<div class="flex grow flex-col gap-3 pl-3">
							<DateViewer
								:date="displayedDate"
								background-color="bg-dark-blue-50"
								text-color="text-dark-blue-700"
								class="border border-dark-blue-200"
							/>

							<div class="flex gap-2">
								<CommonTextInput :label="TranslationKeys.GENERIC_PREVIOUS" :value="previousPrice" :disabled="true" />
								<CommonTextInput :label="TranslationKeys.GENERIC_NEXT" :value="nextPrice" :disabled="true" />
							</div>

							<CommonNumberInput
								:label="TranslationKeys.BASE_PRICE"
								:value="currentPrice"
								@change="onBasePriceChange"
								:helper-text="alteredWeightHelperText"
								data-id="base-price-amount-input"
							/>
							<!--  TODO: aggiungere MIN -->

							<div
								v-if="currentPeriodLength + 1 < minSelectableDays"
								class="flex items-center gap-3 whitespace-normal rounded-[4px] border border-solid border-warning-400 bg-warning-100 p-3"
							>
								<CommonText
									class="text-warning-900"
									:text="TranslationKeys.BASE_PRICES_MIN_DAYS_EXPLAINATION"
									:replacements="[minSelectableDays.toString()]"
								/>
							</div>
						</div>
					</div>
				</div>
			</slot>
		</CommonFrameWithTitle>

		<CommonFrameWithTitle :title="TranslationKeys.PRICE_PREVIEW" class="flex-1">
			<ChartsBasePricesStandard
				:preview-values="previewChartData"
				:base-price-values="basePricesChartData"
				:style="'height: 300px;'"
				:currency="currency"
				:selected-period="displayedDate"
				:display-from="displayFrom"
				:display-to="displayTo"
				:show-days-only="true"
			/>
		</CommonFrameWithTitle>
	</div>
</template>

<style lang="scss">
.base-prices-date-picker {
	.vc-disabled {
		@apply cursor-not-allowed;
	}

	.vc-highlight-base-start,
	.vc-highlight-base-middle,
	.vc-highlight-base-end {
		@apply bg-sky-300;
	}

	.vc-highlight-content-solid {
		@apply text-sky-900;
	}

	.vc-highlight-bg-solid {
		@apply bg-sky-300;
	}
	.vc-day-content.vc-focus {
		@apply shadow-none;
	}
}
</style>
