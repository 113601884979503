import { SpSvg } from '~/autogen/SpSvg'
import { FeedbackTypes } from '../constants/FeedbackTypes'

export interface FeedbackButtonProps {
	bgColor: String
	fillColor: String
	icon: SpSvg
}

export const FeedbackButtonConfig: {
	[k in FeedbackTypes]: FeedbackButtonProps
} = {
	[FeedbackTypes.BAD]: {
		bgColor: 'bg-error-400 hover:bg-error-300 active:bg-error-600',
		fillColor: 'fill-error-900 group-active:fill-white',
		icon: SpSvg.BasicEmojiSad,
	},
	[FeedbackTypes.NEUTRAL]: {
		bgColor: 'bg-warning-400 hover:bg-warning-300 active:bg-warning-600',
		fillColor: 'fill-warning-900 group-active:fill-white',
		icon: SpSvg.BasicEmojiQuite,
	},
	[FeedbackTypes.POSITIVE]: {
		bgColor: 'bg-success-400 hover:bg-success-300 active:bg-success-600',
		fillColor: 'fill-success-900 group-active:fill-white',
		icon: SpSvg.BasicEmojiHappy,
	},
}
