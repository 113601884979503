<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'

const props = defineProps({
	icon: { type: String as PropType<SpSvg>, required: true },
	text: { type: String as PropType<TranslationKey>, required: true },
})
const { icon, text } = toRefs(props)
</script>

<template>
	<div class="flex w-full items-center gap-2 rounded bg-warning-100 p-2">
		<CommonIcon :icon-name="icon" :icon-size="IconSizes.XS" fill="fill-warning-900" />
		<CommonText :text="text" :text-size="TextSizes.BODY_REGULAR" class="text-warning-900" />
	</div>
</template>
