<script lang="ts" setup>
import { data } from 'v-calendar/dist/types/tests/unit/util/dayData.js'
import { PropType, Ref } from 'vue'
import { TestIds } from '~/constants/TestIds'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { BulkUpdateModes } from '~~/src/constants/bulkUpdateModes'
import { IdModal } from '~~/src/constants/IdModal'
import { TrackingMessages } from '~~/src/constants/trackingMessages'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useModalStore } from '~~/src/store/modals'
import { useModifiersStore } from '~~/src/store/modifiers'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { DateRange } from '~~/src/submodules/sharedTypes/common/DateRange'
import { Modifiers, ModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import {
	composeMultipleModeTrackingMessage,
	composeSingleModeTrackingMessage,
} from '~~/src/tracking/bulkUpdateTracking'
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration'
import { utilDate } from '~~/src/utils/utilDate'
import { utilTracking } from '~~/src/utils/utilTracking'

// TODO: clean this component

const props = defineProps({
	config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
	modalId: { type: String as PropType<IdModal>, required: true },
})

const { config, modalId } = toRefs(props)
const modifiersStore = useModifiersStore()
const modalStore = useModalStore()
const accommodationStore = useAccommodationsStore()
let trackMessage = {}

const onSingleUpdate = () => {
	if (modifiersStore.getModifiersConflict(currentSingleModeModifiers.value)) {
		modalStore.addModal(IdModal.BulkUpdateModifiersConflict)
	} else {
		modifiersStore.bulkUpdateSingleRoomsModifiers(date.value!, dateFilter.value, currentSingleModeModifiers.value)
		utilTracking.track(TrackingMessages.BULK_PRICE_CUSTOMIZATION, trackMessage)
		modalStore.closeModal(modalId.value)
	}
}
const onMultipleUpdate = () => {
	modifiersStore.bulkUpdateMultipleRoomsModifiers(
		date.value!,
		dateFilter.value,
		selectedRoomTypes.value,
		currentModifiers.value.tweak!
	)
	utilTracking.track(TrackingMessages.BULK_PRICE_CUSTOMIZATION, trackMessage)
	modalStore.closeModal(modalId.value)
}

const updateTypes = [TranslationKeys.ON_SINGLE_ROOMS, TranslationKeys.ON_MULTIPLE_ROOMS]
const currentEditMode = ref(0)
watch(currentEditMode, () => {
	currentModifiers.value = {}
	currentSingleModeModifiers.value.clear()
	selectedRoomTypes.value = []
})
const updateFunctionToUse = computed(() => (currentEditMode.value == 0 ? onSingleUpdate : onMultipleUpdate))
const onEditModeChange = (index: number) => (currentEditMode.value = index)

const date: Ref<undefined | DateRange> = ref()
const onDateChange = (newVal: any) => (date.value = newVal)

// all days of week
const dateFilter = ref([0, 1, 2, 3, 4, 5, 6])
const onDateFilterChange = (days: number[]) => (dateFilter.value = days)

const selectedRoomTypes: Ref<number[]> = ref([])
const calculatedAlready = ref(false)
const buttonDisabled = ref(!calculatedAlready.value && selectedRoomTypes.value.length < 1)
const realButtonDisabled = computed(() => modifiersHaveErrors.value || buttonDisabled.value)

const configToForward = computed(() => ({
	...config.value,
	buttons: [{ ...config.value.buttons![0]!, effect: updateFunctionToUse.value, disabled: realButtonDisabled }],
}))

const filteredDays = computed((): number[] => utilDate.getMissingDaysOfTheWeek(date.value))
const currentModifiers: Ref<Modifiers> = ref({})
const currentSingleModeModifiers = ref(new Map<number, Modifiers>())
const onModifiersChange = computed(() =>
	currentEditMode.value === 0
		? onSingleModifiersChange
		: (modifiers: Modifiers, ids: number[]) => onMultipleModifiersChange(currentModifiers.value, ids)
)

const onSingleModifiersChange = (modifierMap: Map<number, Modifiers>) => {
	currentSingleModeModifiers.value = modifierMap
	buttonDisabled.value = modifierMap.entries.length > 0 || dateFilter.value.length < 1

	const ids = Array.from(modifierMap.keys())
	const modifiersMap = modifiersStore.getCountActiveModifiers(ids)
	trackMessage = composeSingleModeTrackingMessage(date.value!, dateFilter.value, ids, modifiersMap)

	modifiersStore.setSingleModifiersForModal(date.value!, dateFilter.value, modifierMap, trackMessage)
}

const _onMultipleModifiersChange = (modifiers: Modifiers) =>
	onMultipleModifiersChange(modifiers, selectedRoomTypes.value)
const onMultipleModifiersChange = (modifiers: Modifiers, ids: number[]) => {
	currentModifiers.value = modifiers
	selectedRoomTypes.value = ids
	buttonDisabled.value =
		ids.length < 1 || modifiers.tweak == undefined || (dateFilter.value != undefined && dateFilter.value.length < 1)

	if (buttonDisabled.value == false && !modifiersHaveErrors.value) {
		const modifiersMap = modifiersStore.getCountActiveModifiers(ids)
		trackMessage = composeMultipleModeTrackingMessage(date.value!, dateFilter.value, ids, modifiersMap, modifiers.tweak)
	}
}

const onMultipleModifierRemove = (modifier: ModifierType) => onModifierRemove(selectedRoomTypes.value, modifier)
const onModifierRemove = (ids: number[], modifier: ModifierType) =>
	modifiersStore.bulkRemoveModifier(date.value!, dateFilter.value, ids, modifier)
const modifiersHaveErrors = ref(false)
const onErrorStatusChange = (hasError: boolean) => (modifiersHaveErrors.value = hasError)
const useExternalModifierHandler = computed(() => currentEditMode.value == 1)
const displayExternalModifierHandler = computed(
	() => useExternalModifierHandler.value && selectedRoomTypes.value.length > 0
)
const currency = computed((): Currency => {
	if (selectedRoomTypes.value.length == 0) {
		return {
			code: 'eur',
			locale: 'en',
		}
	}

	const accommodationId = accommodationStore.getRoomTypeById(selectedRoomTypes.value[0]!)!.accommodationId
	return accommodationStore.getAccommodationById(accommodationId)!.currency
})
</script>

<template>
	<ModalGeneric
		:modal-id="modalId"
		:config="configToForward"
		style="width: 600px"
		:data-testid="TestIds.CalendarCustomizePopup"
	>
		<template v-slot:default>
			<div class="flex flex-col gap-6">
				<CommonRangeSelector
					:entries="updateTypes"
					:test-ids="[TestIds.CalendarCustomizePopupSingleButton, TestIds.CalendarCustomizePopupMultiButton]"
					:selected-option="currentEditMode"
					@click="onEditModeChange"
					:data-id="UserflowId.BulkUpdateTypeSelector"
				/>
				<DatePeriodSelector
					@change="onDateChange"
					:date="date"
					:start-date="new Date()"
					:test-ids="{
						'date-picker': TestIds.CalendarCustomizePopupPeriodDatepicker,
						field: TestIds.CalendarCustomizePopupPeriodField,
					}"
					:data-id="UserflowId.BulkUpdateRangeSelector"
					:data-testid="TestIds.CalendarCustomizePopupPeriodField"
				/>
				<CommonWeekDaySelector
					:disabled="date == undefined"
					:selected-range="dateFilter"
					:disabled-days="filteredDays"
					:test-ids="{
						range: [TestIds.CalendarCustomizePopupWeekdaysButton, TestIds.CalendarCustomizePopupWeekendButton],
						days: [
							TestIds.CalendarCustomizePopupSunButton,
							TestIds.CalendarCustomizePopupMonButton,
							TestIds.CalendarCustomizePopupTueButton,
							TestIds.CalendarCustomizePopupWedButton,
							TestIds.CalendarCustomizePopupThuButton,
							TestIds.CalendarCustomizePopupFriButton,
							TestIds.CalendarCustomizePopupSatButton,
						],
					}"
					@change="onDateFilterChange"
					:data-id="UserflowId.DayFilter"
				/>
				<ModifiersBulkUpdate
					:date-range="date!"
					:bulk-update-mode="currentEditMode === 0 ? BulkUpdateModes.SINGLE : BulkUpdateModes.MULTIPLE"
					@change="onModifiersChange"
					@cancel="onModifierRemove"
					@error-status-change="onErrorStatusChange"
					:allow-edit="!useExternalModifierHandler"
					:test-ids="{
						'accommodation-dropdown': TestIds.CalendarCustomizePopupAccomodationDopdown,
						'room-type-list': TestIds.CalendarCustomizePopupRoomsContainer,
						'select-all': TestIds.CalendarCustomizePopupSelectallCheckbox,
						'tweak-left-button': TestIds.CalendarCustomizePopupToggle1,
						'tweak-right-button': TestIds.CalendarCustomizePopupToggle2,
						'remover-modifier-container': TestIds.CalendarCustomizePopupRemovefiltersContainer,
						'filters-container': TestIds.CalendarCustomizePopupFiltersContainer,
					}"
				/>
			</div>
		</template>
		<template v-slot:footer>
			<AnimationCollapse>
				<ModifiersManager
					v-if="displayExternalModifierHandler"
					@cancel="onMultipleModifierRemove"
					@change="_onMultipleModifiersChange"
					:modifiers-filters="[ModifierType.Tweak]"
					:currency="currency"
					@error-status-change="onErrorStatusChange"
					:removable-modifiers="modifiersStore.getCommonModifiersForRoomTypeId(selectedRoomTypes)"
					:associated-modifiers="[modifiersStore.getAssociatedModifiers(selectedRoomTypes)]"
					:test-ids="{
						'filters-container': TestIds.CalendarMultiselectionFiltersContainer,
						'tweak-left-button': TestIds.CalendarMultiselectionToggle1,
						'tweak-right-button': TestIds.CalendarMultiselectionToggle2,
						'remover-modifier-container': TestIds.CalendarMultiselectionRemoveFiltersContainer,
					}"
				/>
			</AnimationCollapse>
		</template>
	</ModalGeneric>
</template>
