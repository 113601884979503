<script lang="ts" setup>
import { PropType } from 'vue'
import { EmitsEnum } from '~/constants/emits'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IMessageWithCondition } from '~~/src/types/IMessageWithCondition'
import { utilString } from '~~/src/utils/utilString'

const props = defineProps({
	value: { type: String, default: '' },
	placeholder: { type: String as PropType<TranslationKey> },
	errorCondition: { type: Object as PropType<IMessageWithCondition<any>> },
	label: { type: String as PropType<TranslationKey>, default: undefined },
	helperText: { type: String as PropType<TranslationKey>, default: undefined },
	isPercentage: { type: Boolean, default: false },
	testIds: { type: { input: String }, required: false },
})
const { value, placeholder: _placeholder, errorCondition, label, helperText, isPercentage } = toRefs(props)

const emit = defineEmits([EmitsEnum.Change, EmitsEnum.FocusOut])
const onChange = (val: any) => {
	const text = val.target.value
	value.value = text
	emit(EmitsEnum.Change, text, utilString.toInteger(text))
}
const onFocusOut = () => emit(EmitsEnum.FocusOut, value.value, utilString.toInteger(value.value))

const placeholder = computed(() =>
	useLocale()
		.translate(_placeholder?.value || ('0' as TranslationKey))
		.toString()
)
const isError = computed(
	() => errorCondition?.value != undefined && errorCondition.value.condition(value.value.toString())
)
const rightPadding = computed(() => (isPercentage.value ? ' pr-8' : ''))
const border = computed(() =>
	isError.value ? 'border-error-400 outline-error-400 border-2' : 'border-sky-200 outline-sky-200'
)
const additionalClasses = computed(() => [rightPadding.value, border.value].join(' '))

defineExpose({
	hasError: () => isError.value,
})
</script>

<template>
	<div class="flex flex-col gap-1">
		<CommonLabel :text="label">
			<div class="relative">
				<CommonIcon
					v-if="isError"
					:icon-name="SpSvg.BasicErrorCircle"
					class="absolute right-3 top-1/2"
					style="transform: translateY(-50%)"
					fill="fill-error-400"
				/>
				<div class="relative flex items-center">
					<input
						type="number"
						:value="value"
						:placeholder="placeholder"
						class="border-1 w-full rounded-md border bg-sky-100 p-2 text-right text-sm text-black"
						:class="additionalClasses"
						@input="onChange"
						@change="onChange"
						@focusout="onFocusOut"
						:data-testid="testIds?.input"
					/>
					<span v-if="isPercentage" class="absolute right-4 text-dark-blue-500">%</span>
				</div>
			</div>
			<CommonText v-if="isError" class="text-error-500" :text="errorCondition!.message" />
			<CommonText v-else-if="helperText != undefined" class="text-sky-500" :text="helperText" />
		</CommonLabel>
	</div>
</template>
