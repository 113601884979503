<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { TextSizes } from '~~/src/constants/textSizes'

const props = defineProps({
	stepNames: { type: Object as PropType<TranslationKey[]>, required: true },
	currentStep: { type: Number, default: 0 },
})
const { stepNames, currentStep } = toRefs(props)
</script>

<template>
	<div class="flex flex-col gap-6">
		<!-- steps -->
		<div class="flex gap-1">
			<div v-for="(step, index) in stepNames" class="group flex flex-1 flex-col gap-1.5">
				<CommonText
					:text="step as TranslationKey"
					:text-size="TextSizes.BODY_REGULAR"
					:class="index === currentStep ? 'text-sky-600' : 'text-dark-blue-200'"
				/>
				<div class="h-1 w-full" :class="index === currentStep ? 'bg-sky-600' : 'bg-dark-blue-200'" />
			</div>
		</div>

		<!-- body -->
		<slot :name="`step-${currentStep}`"></slot>
	</div>
</template>
