<script lang="ts" setup>
import { EmitsEnum } from '~~/src/constants/emits'

const props = defineProps({
	min: { type: Number, default: 0 },
	max: { type: Number, required: true },
	startValue: { type: Number, required: true },
	gradientFrom: { type: String, default: 'from-warm-grey-400' },
	gradientTo: { type: String, default: 'to-warning-400' },
})
const { startValue, min, max, gradientFrom, gradientTo } = toRefs(props)

const emit = defineEmits([EmitsEnum.Change, EmitsEnum.FocusOut])
const onChange = (index: any) => {
	let val = startValue.value
	try {
		val = parseInt(index.target.value)
	} catch {}

	emit(EmitsEnum.Change, val)
}
const onFocusOut = () => emit(EmitsEnum.FocusOut)

const gradientColors = computed(() => [gradientFrom.value, gradientTo.value].join(' '))
</script>

<template>
	<div class="slider-container w-full">
		<input
			type="range"
			:min="min"
			:max="max"
			:value="startValue"
			class="slider h-2 w-full bg-gradient-to-r"
			:class="gradientColors"
			@input="onChange"
			@focusout="onFocusOut"
			style="
				--thumb-diameter: 15px;
				--thumb-background: #272e3a;
				--thumb-hover-background: #9191ff;
				--thumb-circle: solid 1px #d3d5d9;
			"
		/>
	</div>
</template>

<style scoped>
.slider {
	-webkit-appearance: none;
	border-radius: 8px;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: var(--thumb-diameter);
	height: var(--thumb-diameter);
	background: var(--thumb-background);
	box-shadow:
		0 0 0 6px var(--thumb-background),
		0px 1.58px 3.16px 6px rgba(0, 0, 0, 0.06),
		0px 1.58px 4.74px 6px rgba(0, 0, 0, 0.1);
	border: var(--thumb-circle);

	border-radius: 100%;
	cursor: pointer;
}

.slider::-webkit-slider-thumb:hover {
	background: var(--thumb-hover-background);
	box-shadow:
		0 0 0 6px var(--thumb-hover-background),
		0px 1.58px 3.16px 6px rgba(0, 0, 0, 0.06),
		0px 1.58px 4.74px 6px rgba(0, 0, 0, 0.1);
}

.slider::-moz-range-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: var(--thumb-diameter);
	height: var(--thumb-diameter);
	background: var(--thumb-background);
	cursor: pointer;
	box-shadow:
		0 0 0 6px var(--thumb-background),
		0px 1.58px 3.16px 6px rgba(0, 0, 0, 0.06),
		0px 1.58px 4.74px 6px rgba(0, 0, 0, 0.1);
	border: var(--thumb-circle);
	border-radius: 100%;
}

.slider::-moz-range-thumb:hover {
	background: var(--thumb-hover-background);
	box-shadow:
		0 0 0 6px var(--thumb-hover-background),
		0px 1.58px 3.16px 6px rgba(0, 0, 0, 0.06),
		0px 1.58px 4.74px 6px rgba(0, 0, 0, 0.1);
}
</style>
