<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core'
import { PropType } from 'nuxt/dist/app/compat/vue-demi'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { IconSizes } from '~~/src/constants/iconSizes'
import { IdModal } from '~~/src/constants/IdModal'
import { SIZE_TABLET_LANDSCAPE } from '~~/src/constants/screenSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useModalStore } from '~~/src/store/modals'
import { IGenericModalConfiguration } from '~~/src/types/IGenericModalConfiguration'
import { useBasePricesStore } from '@/store/basePrices'
import { cloneDeep } from 'lodash'
import { BasePrice } from '~/submodules/sharedTypes/common/BasePrice'

const props = defineProps({
	config: { type: Object as PropType<IGenericModalConfiguration>, required: true },
	modalId: { type: String as PropType<IdModal>, required: true },
	isMobileModal: { type: Boolean, default: false },
})
const { config, modalId, isMobileModal } = toRefs(props)

const slots = useSlots()
const hasDefaultSlot = computed(() => slots.default != undefined)
const hasFooterSlot = computed(() => slots.footer != undefined)
const modalStore = useModalStore()
const basePricesStore = useBasePricesStore()

const { width } = useWindowSize()
const isMobile = computed(() => width.value <= SIZE_TABLET_LANDSCAPE)
watch(width, () => {
	const visualizationModeMismatch =
		// mobile on desktop res
		(!isMobile.value && isMobileModal.value) ||
		// desktop on mobile res
		(isMobile.value && !isMobileModal.value)

	if (visualizationModeMismatch) {
		modalStore.closeModal(modalId.value)
	}
})

const hasButtons = computed(() => {
	const conf = config.value

	return (
		(conf.buttons != undefined && conf.buttons.length > 0) ||
		conf.cancelButton != undefined ||
		conf.dangerButton != undefined
	)
})

const getGenericButtons = computed(() => config.value.buttons || [])

const isCancelOnLeft = computed(() => {
	const conf = config.value

	let buttonCount = (conf.buttons || []).length
	if (conf.dangerButton) {
		buttonCount++
	}

	return buttonCount > 1 && conf.cancelButton != undefined
})

const onClose = () => {
	modalStore.closeModal(modalId.value)
}

const getButtonType = (index: number): ButtonTypes => {
	if (config.value.dangerButton != undefined) {
		return config.value.buttons![index]?.buttonType || ButtonTypes.TERTIARY
	}

	return config.value.buttons![index]?.buttonType || index == getGenericButtons.value.length - 1
		? ButtonTypes.PRIMARY
		: ButtonTypes.TERTIARY
}

const getTextReplacements = computed(() =>
	config.value.textReplacements == undefined ? [] : config.value.textReplacements()
)

const confirm = () => {
	const selectedRoomTypeIds = basePricesStore.selectedRoomTypeIds
	const selectedBasePrices = basePricesStore.selectedBasePrices
	duplicatePeriod(selectedRoomTypeIds, selectedBasePrices)
	modalStore.closeModal(modalId.value)
}

const duplicatePeriod = (selectedRoomTypeIds: number[], selectedBasePrices: BasePrice[]) => {
	const priceModifiers = basePricesStore.priceModifiers
	const accommodationId = basePricesStore.selectedAccommodationId

	selectedBasePrices.forEach((bp: BasePrice) => {
		const clonedBp = cloneDeep(bp)
		selectedRoomTypeIds.forEach((selectedRoomTypeId: number) => {
			const currentBasePrices = basePricesStore.getBasePricesByAccommodationAndRoomTypeId(
				accommodationId,
				selectedRoomTypeId
			)
			currentBasePrices.forEach((currentBp) => {
				if (clonedBp.dateRange.from <= currentBp.dateRange.to && clonedBp.dateRange.from >= currentBp.dateRange.from) {
					if (clonedBp.dateRange.to < currentBp.dateRange.to) {
						const splittedBp = {
							accommodationId,
							roomTypeId: selectedRoomTypeId,
							basePrice: {
								price: clonedBp.price,
								dateRange: {
									from: clonedBp.dateRange.to,
									to: currentBp.dateRange.to,
								},
							},
						}
						basePricesStore.addBasePrice(splittedBp)
					}
					currentBp.dateRange.to = clonedBp.dateRange.to
				}
				if (clonedBp.dateRange.to >= currentBp.dateRange.from && clonedBp.dateRange.to <= currentBp.dateRange.to) {
					currentBp.dateRange.from = clonedBp.dateRange.to
				}
			})

			const modifier = priceModifiers.get(selectedRoomTypeId)
			const calculatedBp = cloneDeep(clonedBp)
			if (modifier && clonedBp.price) {
				if (modifier.variationType == 'amount') calculatedBp.price = clonedBp.price + modifier.variationValue
				else if (modifier.variationType == 'percentage') {
					calculatedBp.price = Math.ceil(clonedBp.price + clonedBp.price * modifier.variationValue)
					calculatedBp.price = calculatedBp.price === 0 ? 1 : calculatedBp.price
				}
			} else {
				calculatedBp.price = null
			}

			const editableBasePrice = {
				accommodationId,
				roomTypeId: selectedRoomTypeId,
				basePrice: calculatedBp,
			}
			basePricesStore.addBasePrice(editableBasePrice)
		})
	})
	modalStore.closeModal(modalId.value)
	basePricesStore.setConfirmedAdd(!basePricesStore.confirmedAdd)
}

onBeforeUnmount(() => {
	if (config.value.onClose != undefined) {
		config.value.onClose()
	}
})
</script>

<template>
	<div class="relative flex w-[608px] flex-col gap-5 rounded-md bg-white p-4 shadow-md" style="max-height: 90%">
		<div class="flex flex-col">
			<CommonText
				v-if="config.label"
				:text-size="TextSizes.PRODUCTIVE"
				:text="config.label"
				class="text-dark-blue-500"
			/>
			<CommonText :text-size="TextSizes.HEADING_MD_REGULAR" :text="config.title" class="text-sky-600" />
		</div>
		<div v-if="config.allowClose" class="absolute right-5 top-5">
			<CommonButton
				:type="ButtonTypes.GHOST"
				:size="ButtonSizes.ICON_S"
				@click="onClose"
				:data-id="UserflowId.ModalClose"
			>
				<CommonIcon :icon-name="SpSvg.BasicTimes" :icon-size="IconSizes.XS" />
			</CommonButton>
		</div>
		<CommonText
			v-if="config.text"
			:text="config.text"
			:text-size="TextSizes.BODY_REGULAR"
			:replacements="getTextReplacements"
			class="text-black"
		/>
		<div v-if="hasDefaultSlot" class="overflow-y-auto">
			<slot></slot>
		</div>
		<div v-if="hasFooterSlot">
			<slot name="footer"></slot>
		</div>
		<div v-if="hasButtons" class="flex flex-row justify-between">
			<!-- left buttons -->
			<div class="pt-4">
				<CommonButton
					v-if="isCancelOnLeft"
					:type="config.cancelButton!.buttonType || ButtonTypes.SECONDARY"
					@click="config.cancelButton!.effect"
					:size="ButtonSizes.TEXT_M"
				>
					<CommonText :text="config.cancelButton!.text" />
				</CommonButton>
			</div>

			<!-- right buttons -->
			<div class="flex flex-row gap-2 pt-4">
				<!-- cancel button -->
				<CommonButton
					v-if="!isCancelOnLeft && config.cancelButton != undefined"
					:type="config.cancelButton!.buttonType || ButtonTypes.SECONDARY"
					@click="config.cancelButton!.effect"
					:size="ButtonSizes.TEXT_M"
				>
					<CommonText :text="config.cancelButton!.text" />
				</CommonButton>

				<!-- regular buttons -->
				<CommonButton
					v-for="(button, index) in getGenericButtons"
					@click="confirm"
					:type="getButtonType(index)"
					:size="ButtonSizes.TEXT_M"
					:disabled="button.disabled?.value as boolean"
					:data-id="`modal-regular-action-${index}`"
				>
					<CommonText :text="button.text" />
				</CommonButton>

				<!-- danger button -->
				<CommonButton
					v-if="config.dangerButton != undefined"
					:type="ButtonTypes.DANGER"
					@click="config.dangerButton!.effect"
					:size="ButtonSizes.TEXT_M"
				>
					<CommonText :text="config.dangerButton!.text" />
				</CommonButton>
			</div>
		</div>
	</div>
</template>
