<script lang="ts" setup>
import { useClipboard } from '@vueuse/core'
import { PropType } from 'vue'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { specialMessagesConfig } from '~~/src/config/SpecialMessagesConfig'

const props = defineProps({
	text: { type: String, required: true },
	onClickMessage: { type: String as PropType<TranslationKey>, default: TranslationKeys.CLIPBOARD_TEXT_SAVED },
})
const { text, onClickMessage } = toRefs(props)
const { copy } = useClipboard()

const showMessage = ref(false)
const onClick = () => {
	showMessage.value = true
	copy(text.value)

	setTimeout(() => (showMessage.value = false), specialMessagesConfig.clipboardTextMessageDurationMs)
}
</script>

<template>
	<div
		class="relative w-full cursor-pointer items-center justify-center rounded-md border border-warm-grey-200 bg-warm-grey-100"
		@click="onClick"
	>
		<CommonTooltip :text="onClickMessage" :show="showMessage" class="flex w-full items-center justify-center p-2">
			<span class="text-sky-700 active:bg-sky-500 active:text-white">{{ text }}</span>
		</CommonTooltip>
	</div>
</template>
