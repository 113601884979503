import { ModifierType } from './../submodules/sharedTypes/common/Modifiers'

export interface IQuickActionConfig {
	borderDivide: string
}

export const quickActionConfig: { [k in ModifierType]: IQuickActionConfig } = {
	[ModifierType.Frozen]: {
		borderDivide: 'border-sky-200',
	},
	[ModifierType.Max]: {
		borderDivide: 'border-warm-grey-200',
	},
	[ModifierType.Min]: {
		borderDivide: 'border-warm-grey-200',
	},
	[ModifierType.Tweak]: {
		borderDivide: 'border-error-200',
	},
}
